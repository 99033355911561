import React, {useContext} from "react";
import {Route, RouteProps} from "react-router-dom";
import {LogIn} from "../pages/logIn/LogIn";
import {UserContext} from "../context/userContext";
import {isAdmin} from "../utils/functions";


export const AdminRoute: React.FunctionComponent<RouteProps> = ({ component, ...options }) => {
    const { user } = useContext(UserContext);
    const finalComponent = isAdmin(user.userType) ? component : LogIn;
    return <Route {...options} component={finalComponent} />;
  };