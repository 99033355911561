import React, {useEffect, useState} from 'react';
import {GridContainer} from "../../components/sharded/layout/GridContainer";
import {GridItem} from "../../components/sharded/layout/GridItem";
import {ApiController} from "../../controller/ApiController";
import {getLicenses} from "../../apiCalls/serverRequests/get";
import {DisplayLicenseRows} from "./DisplayLicenseRows";
import {LicenseDomainPlugin} from "../../types/license";
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {dFlex, mAuto, mLeftAuto} from "../../constants/styles";
import {AddLicense} from "../../components/license/addLicense/AddLicense";
import {SkeletonTableRows} from "../../components/sharded/loader/SkeletonTableRows";
import {quickSort} from "../../utils/quicksort";
import {Order} from "../../types/utilTypes";
import {usePrevious} from "../../utils/functions";
import {SearchBar} from "../../components/SearchBar";

type DisplayLicensesProps = {}

const tableH = [
    {
        id: 0,
        key: "domain",
        label: "Domain"
    },
    {
        id: 1,
        key: "name",
        label: "Pluginname"
    },
    {
        id: 2,
        key: "buy_date",
        label: "Kaufdatum"
    },
    {
        id: 3,
        key: "end_date",
        label: "Abluafdatum"
    },
    {
        id: 4,
        key: "licenseType",
        label: "Lizenzart"
    }
]

export type SearchObject = {
    column: string,
    value: string,
}

export const DisplayLicenses: React.FunctionComponent<DisplayLicensesProps> = () => {
    const [licenses, setLicenses] = useState<[LicenseDomainPlugin] | null>(null);
    const [updateLicenses, setUpdateLicenses] = useState<boolean>(true);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<string>("");
    const [isSorting, setIsSorting] = React.useState<boolean>(true);
    const [search, setSearch] = React.useState<{ [key: string]: SearchObject }>({});
    const prevOrderKey = usePrevious(orderBy);

    const initOrder = (orderByKey: string) => {
        setIsSorting(true);
        setOrderBy(orderByKey)
        setOrder(orderByKey !== prevOrderKey ? "asc" : (order === "asc" ? "desc" : "asc"))
    }

    const successHandler = (result: typeof licenses) => {
        setUpdateLicenses(false);
        setLicenses(result);
        setIsSorting(false)
    }

    const searchColumn = (value: SearchObject["value"], column: SearchObject["column"]) => setSearch( {
        ...search,
        [column]: {
            column,
            value
        }
    });

    useEffect(() => {
        if (licenses) {
            let arrLength = licenses.length;
            if (arrLength > 1) {
                quickSort(licenses, 0, arrLength - 1, orderBy, order);
                setLicenses([...licenses]);
            }
        }
        setIsSorting(false);
    }, [order, orderBy])


    return (
        <GridContainer>
            <GridItem xs={12} style={dFlex}>
                <div style={mLeftAuto}>
                    <AddLicense onSuccess={() => setUpdateLicenses(true)}/>
                </div>
            </GridItem>
            <GridItem xs={12}>
                <Table component={"div"}>
                    <TableHead component={"div"}>
                        <TableRow component={"div"}>
                            {tableH.map((head,index) =>
                                <TableCell
                                    key={index}
                                    sortDirection={orderBy === head.key ? order : false}
                                    component={"div"}
                                >
                                    <div style={dFlex}>
                                        <SearchBar handleChange={(event) => searchColumn(event.target.value, head.key)}
                                                   placeholder={head.label}
                                                   searchKey={search[head.key] ? search[head.key].value : ""}

                                        />
                                        <TableSortLabel
                                            active={orderBy === head.key}
                                            direction={orderBy === head.key ? order : 'asc'}
                                            onClick={() => initOrder(head.key)}
                                            disabled={isSorting}
                                            component={"div"}
                                        >
                                            sort
                                        </TableSortLabel>
                                    </div>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <ApiController loading={updateLicenses} apiCall={getLicenses} onSuccess={successHandler}
                                   loadingComponent={<SkeletonTableRows rowCount={20} colCount={5}/>}>
                        {() => licenses && (
                            <TableBody component={"div"}>
                                <DisplayLicenseRows licenses={licenses} search={search}/>
                            </TableBody>
                        )}
                    </ApiController>
                </Table>
            </GridItem>
        </GridContainer>
    )
};