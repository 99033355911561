import React, {useState} from 'react';
import './App.css';
import {Navigation} from './components/sharded/layout/Navigation';
import {BrowserRouter} from "react-router-dom";
import {AppRoutes} from "./routes/AppRoutes";
import {USER_DEFAULT_VALUE, User, UserContext} from './context/userContext';
import {CheckUserSession} from "./components/sharded/user/CheckUserSession";
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {createMuiTheme, ThemeProvider} from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        type: "dark"
    }
});

function App() {
    const [user, setUser] = useState<User>(USER_DEFAULT_VALUE);
    return (
        <UserContext.Provider value={{user, setUser}}>
            <CheckUserSession setUser={setUser}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ThemeProvider theme={theme}>
                        <div className="App">
                            <BrowserRouter>
                                <Navigation>
                                    <AppRoutes/>
                                </Navigation>
                            </BrowserRouter>
                        </div>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </CheckUserSession>
        </UserContext.Provider>);
}

export default App;