import React, {useState} from 'react';
import {Button, createStyles, Modal, Paper, Typography} from "@material-ui/core";
import {dFlex, margins, mAuto, mLeftAuto} from "../../../constants/styles";
import {GridContainer} from "../../sharded/layout/GridContainer";
import {GridItem} from "../../sharded/layout/GridItem";
import {Domain, JtlPlugin, LicenseType} from "../../../types/license";
import {Formik} from "formik"
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {SelectPlugin} from "./formFields/SelectPlugin";
import {SelectLicenseType} from "./formFields/SelectLicenseType";
import {DomainInput} from "./formFields/DomainInput";
import {insertNewLicense} from "../../../apiCalls/serverRequests/post";
import {NewLicenseData} from "../../../types/api";
import {Override} from "../../../types/utilTypes";
import {changeType} from "../../../utils/functions";
import {API_ERROR} from "../../../constants/api";
import {ApiController} from "../../../controller/ApiController";
import {getDomains, getPlugins} from "../../../apiCalls/serverRequests/get";
import {LoadingAnimation} from "../../sharded/LoadingAnimation";

const useStyles = makeStyles(() =>
    createStyles({
        picker: {
            cursor: 'pointer',
            '& *': {
                textAlign: 'center',
                cursor: 'pointer',
            }
        },
    }),
);

type AddLicenseProps = {
    onSuccess: () => void
}

type FormikValuesProps = Override<NewLicenseData, { plugin: JtlPlugin | null }>

export const AddLicense: React.FunctionComponent<AddLicenseProps> = ({ onSuccess }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [plugins, setPlugins] = useState<[JtlPlugin] | null>(null);
    const [domains, setDomains] = useState<[Domain] | null>(null);

    const [submitErrorMsg, setSubmitErrorMsg] = useState<string>("");
    const classes = useStyles();
    const format = "DD.MM.YYYY, HH:mm:ss";
    const closeModal = () => setOpen(false);
    return (
        <React.Fragment>
            {!plugins && <ApiController apiCall={getPlugins} onSuccess={setPlugins}/>}
            {!domains && <ApiController apiCall={getDomains} onSuccess={setDomains}/>}
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Neue Lizenz hinzufügen
            </Button>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={dFlex}
            >
                <Paper style={{...mAuto, padding: margins["m"], width: 1000, maxWidth: "100%"}}>
                    {(!plugins || !domains) ? (
                        <LoadingAnimation/>
                    ) : (
                        <Formik<FormikValuesProps>
                            validateOnBlur={false}
                            validateOnChange={false}
                            validateOnMount={false}
                            initialValues={{
                                plugin: null,
                                domain: "",
                                buy_date: moment().format(format),
                                end_date: moment().format(format),
                                licenseType: LicenseType["Demo Lizenz"]
                            }}
                            onSubmit={(values) => {
                                if (values.plugin !== null) {
                                    values.domain = values.domain.replace(/\s/g, '');
                                    // prevent compiler error: type null | jtlPlugin !== JtlPlugin
                                    let nValues = changeType<NewLicenseData>(values);
                                    insertNewLicense(nValues).then(({result, status}) => {
                                        if (status && status.type === API_ERROR) {
                                            setSubmitErrorMsg(status.message);
                                        } else {
                                            setSubmitErrorMsg("");
                                            onSuccess();
                                            closeModal();
                                        }
                                    });
                                }
                            }}>
                            {props => {
                                const changeStartDate = (date: MaterialUiPickersDate) => date && props.setValues({
                                    ...props.values,
                                    buy_date: date.format(format),
                                    end_date: date.isAfter(moment(props.values.end_date, format)) ? date.format(format) : props.values.end_date
                                })
                                const changeEndDate = (date: MaterialUiPickersDate) => date && props.setValues({
                                    ...props.values,
                                    end_date: date.format(format)
                                });

                                const handleAutoCompleteChange = (key: string, value: any) => props.setValues({
                                    ...props.values,
                                    [key]: value
                                })

                                return (
                                    <form onSubmit={props.handleSubmit}>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                                <SelectPlugin
                                                    handleChange={(value) => handleAutoCompleteChange("plugin", value)}
                                                    plugins={plugins}
                                                />
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <SelectLicenseType
                                                    handleChange={(value) => handleAutoCompleteChange("licenseType", value)}/>
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <DomainInput
                                                    handleChange={(value) => handleAutoCompleteChange("domain", value)}
                                                    domains={domains}
                                                />
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <div style={dFlex}>
                                                    {/*
                                                <Typography
                                                    style={{margin: 'auto 16px'}}><strong>gültig ab</strong>
                                                </Typography>
                                                <DateTimePicker
                                                    ampm={false}
                                                    name="buy_date"
                                                    className={classes.picker}
                                                    format={format}
                                                    value={moment(props.values.buy_date, format)}
                                                    onChange={changeStartDate}
                                                    TextFieldComponent={CustomInputForDate}
                                                />
                                                <Typography
                                                    style={{margin: 'auto 16px'}}><strong>bis</strong>
                                                </Typography>
                                                <DateTimePicker
                                                    ampm={false}
                                                    name="end_date"
                                                    minDate={moment(props.values.buy_date, format)}
                                                    className={classes.picker}
                                                    format={format}
                                                    TextFieldComponent={CustomInputForDate}
                                                    value={moment(props.values.end_date, format)}
                                                    onChange={changeEndDate}
                                                />*/}
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} style={dFlex}>
                                                <div style={mLeftAuto}>
                                                    <Button variant="contained" color="default" onClick={closeModal}>
                                                        schließen
                                                    </Button>
                                                    <Button style={{marginLeft: margins["s"]}} variant="contained"
                                                            color="primary" type="submit">
                                                        Lizenz anlegen
                                                    </Button>
                                                </div>
                                            </GridItem>
                                            {submitErrorMsg !== "" && (
                                                <GridItem xs={12} style={dFlex}>
                                                    <div style={mLeftAuto}>
                                                        <Typography color="error">{submitErrorMsg}</Typography>
                                                    </div>
                                                </GridItem>
                                            )}
                                        </GridContainer>
                                    </form>
                                )
                            }}
                        </Formik>
                    )}
                </Paper>
            </Modal>
        </React.Fragment>
    );
}