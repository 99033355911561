export type MarginsType = {
    none: string;
    xs: string;
    s: string;
    m: string;
    l: string;
    xl: string;
    xxl: string;
}

export const margins: MarginsType = {
    none: "0",
    xs: "8px",
    s: "16px",
    m: "24px",
    l: "32px",
    xl: "48px",
    xxl: "72px"
};
export type GridMarginType = {
    marginType?: "none" | "xs" | "s" | "m" | "l" | "xl" | "xxl";
    paddingType?: "none" | "xs" | "s" | "m" | "l" | "xl" | "xxl";
}
export type GridMarginObject = {
    marginTop: MarginsType[keyof MarginsType];
    marginBottom: MarginsType[keyof MarginsType];
    paddingTop: MarginsType[keyof MarginsType];
    paddingBottom: MarginsType[keyof MarginsType];
}

export const mLeftAuto = {marginLeft: 'auto'};

export const mRightAuto = {marginRight: 'auto'};

export const dFlex = {display: 'flex'};

export const mAuto = {margin: 'auto'};

export const fWidth = {width: '100%'};
