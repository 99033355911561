import React from 'react';
import {TableCell, TableRow} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {SkeletonTableCells} from "./SkeletonTableCells";

type SkeletonTableRowsProps = {
    colCount?: number,
    rowCount?: number
}

export const SkeletonTableRows: React.FunctionComponent<SkeletonTableRowsProps> = ({colCount = 1, rowCount = 1}) => {
    return <React.Fragment>
        {[...Array(rowCount)].map(
            (value, index) => (
                <TableRow key={index} component={"div"}>
                    <SkeletonTableCells colCount={colCount} />
                </TableRow>
            ))}
    </React.Fragment>
}