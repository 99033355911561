import * as React from 'react'

export const USER_DEFAULT_VALUE = {
    userId: null,
    userName: null,
    email: null,
    company: null,
    userType: null,
    sessionId: null,
}


export type User = {
    userId: null | number,
    userName: null | string,
    email: null | string,
    company: null | string,
    userType: null | number,
    sessionId: null | string,
}
export type UserContextType = {
    user: User,
    setUser: (user: User) => void
}

export const UserContext = React.createContext<UserContextType>({
    user: USER_DEFAULT_VALUE,
    setUser: (user: User) => user,
});