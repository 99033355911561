import React, {useState} from 'react';
import {GridContainer} from "../../components/sharded/layout/GridContainer";
import {GridItem} from "../../components/sharded/layout/GridItem";
import {ApiController} from "../../controller/ApiController";
import {getPlugins} from "../../apiCalls/serverRequests/get";
import {DisplayPluginRows} from "./DisplayPluginRows";
import {JtlPlugin, LicenseType} from "../../types/license";
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {dFlex, mLeftAuto} from "../../constants/styles";
import {AddPlugin} from "../../components/plugin/addPlugin/AddPlugin";
import {SkeletonTableRows} from "../../components/sharded/loader/SkeletonTableRows";
import {NewPluginData} from "../../types/api";
import {v4 as uuidv4} from "uuid";

type DisplayLicensesProps = {}

const defaultPluginData = {
    name: "",
    secretKey: uuidv4(),
    version: "500",
    duration: "1Y",
    demo_duration: "14D",
    plugin_licenseType: LicenseType["Einmalige Kauflizenz"]
}

export const DisplayPlugins: React.FunctionComponent<DisplayLicensesProps> = () => {
    const [plugins, setPlugins] = useState<[JtlPlugin] | null>(null);
    const [updatePlugins, setUpdatePlugins] = useState<boolean>(true);
    const [pluginData, setPluginData] = useState<NewPluginData>(defaultPluginData);
    const [pluginId, setPluginId] = useState<JtlPlugin["pid"]>(-1);
    const [open, setOpen] = useState<boolean>(false);

    const successHandler = (result: typeof plugins) => {
        setUpdatePlugins(false);
        setPlugins(result)
    }

    const editPlugin = (pluginData: NewPluginData | JtlPlugin, pluginId: JtlPlugin["pid"]) => [
        setPluginData(pluginData), setPluginId(pluginId), setOpen(true)
    ]

    const addPlugin = () => [editPlugin(defaultPluginData, -1), setOpen(true)];

    return (
        <React.Fragment>
            <GridContainer>
                <GridItem xs={12} style={dFlex}>
                    <div style={mLeftAuto}>
                        <Button variant="contained" color="primary" onClick={() => addPlugin()}>
                            Neues Plugin hinzufügen
                        </Button>
                    </div>
                </GridItem>
                <GridItem xs={12}>
                    <Table component={"div"}>
                        <TableHead component={"div"}>
                            <TableRow component={"div"}>
                                <TableCell component={"div"}>Pluginname</TableCell>
                                <TableCell component={"div"}>PluginId</TableCell>
                                <TableCell component={"div"}>Secret key</TableCell>
                                <TableCell component={"div"}>Version</TableCell>
                                <TableCell component={"div"}>Shop-Version</TableCell>
                                <TableCell component={"div"}>Lizenzdauer</TableCell>
                                <TableCell component={"div"}>Demodauer</TableCell>
                                <TableCell component={"div"}>Lizenzart</TableCell>
                                <TableCell component={"div"}>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <ApiController loading={updatePlugins} apiCall={getPlugins} onSuccess={successHandler}
                                       loadingComponent={
                                           <SkeletonTableRows rowCount={20} colCount={9}/>
                                       }>
                            {() => (
                                <TableBody component={"div"}>
                                    {plugins && (
                                        <DisplayPluginRows plugins={plugins} editPlugin={editPlugin}/>
                                    )}
                                </TableBody>
                            )}
                        </ApiController>
                    </Table>
                </GridItem>
            </GridContainer>
            <AddPlugin
                onSuccess={() => setUpdatePlugins(true)}
                initialValues={pluginData}
                pluginId={pluginId}
                open={open}
                setOpen={setOpen}
            />
        </React.Fragment>)
};