import React from 'react';
import {checkSession} from "../../../utils/functions";
import {ApiController} from "../../../controller/ApiController";
import {User} from "../../../context/userContext";

type CheckUserSessionProps = {
    children: React.ReactNode,
    setUser: (user: User) => void,
}

export const CheckUserSession: React.FunctionComponent<CheckUserSessionProps> = ({children, setUser}) => {
    const apiCall = () => Promise.resolve().then(() => checkSession().then((user: User | null) => user && setUser(user)));
    return (
        <React.Fragment>
            <ApiController apiCall={apiCall}>
                {(isLoading) => !isLoading && children}
            </ApiController>
        </React.Fragment>
    )
}