import React, {ChangeEvent} from 'react';
import {Input, InputAdornment} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

type SearchBarProps = {
    searchKey?: string,
    handleChange: (event: ChangeEvent<{ value: string }>) => void,
    placeholder: string
}

export const SearchBar: React.FunctionComponent<SearchBarProps> = ({ searchKey = "", handleChange, placeholder }) => {
    return (
        <Input value={searchKey || ""} onChange={handleChange} disableUnderline
               placeholder={placeholder}
               startAdornment={
                   <InputAdornment position="start">
                       <FontAwesomeIcon icon={faSearch}/>
                   </InputAdornment>}
        />
    );
}