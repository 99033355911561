import React, {useEffect, useState} from "react";
import {LoadingAnimation} from "../components/sharded/LoadingAnimation";
import {APIResult} from "../types/api";

type ApiControllerProps = {
    apiCall: () => any;
    loading?: boolean;
    onSuccess?: (response: any) => void;
    onError?: () => void;
    children?: (isLoading: boolean, setIsLoading: (isLoading: boolean) => void) => any;
    loadingComponent?: React.ReactNode;
}

export const ApiController: React.FunctionComponent<ApiControllerProps> = ({loading = true, apiCall, onSuccess, onError = console.log,loadingComponent= <LoadingAnimation />, children}) => {
    const [isLoading, setIsLoading] = useState<boolean>(loading)

    useEffect(() => {
        isLoading && Promise.resolve("x")
            .then(() => apiCall())
            .then((response: APIResult<any>) => onSuccess && onSuccess(response.result))
            .then(() => setIsLoading(false))
            .catch(error => onError(error))
    }, [isLoading, onError, onSuccess, apiCall]);

    useEffect(() => {
        loading && setIsLoading(loading);
    }, [loading]);

    return children ? (<React.Fragment>
        {isLoading ? loadingComponent : children(isLoading, setIsLoading)}
    </React.Fragment>) : null;
}