import React from "react";
import {Route, Switch} from "react-router-dom";
import {getLicensesPath, getLogInPath, getLogOutPath, getPluginPath,} from "./paths";
import {LoggedOut} from "../pages/loggOut/LoggedOut";
import {LogIn} from "../pages/logIn/LogIn";
import {AdminRoute} from "./AdminRoute";
import {DisplayLicenses} from "../pages/displayLicenses/DisplayLicenses";
import {DisplayPlugins} from "../pages/plugins/DisplayPlugins";

export const AppRoutes: React.FunctionComponent = () => (
    <Switch>
        <Route path={getLogInPath()} exact component={LogIn}/>
        <Route path={getLogOutPath()} exact component={LoggedOut}/>
        <Route path={getPluginPath()} exact component={DisplayPlugins}/>
        <AdminRoute path={getLicensesPath()} exact component={DisplayLicenses}/>
    </Switch>
)