import {User} from "../context/userContext";
import {ADMIN_USER} from "../constants/const";
import {validateUserSession} from "../apiCalls/serverRequests/post";
import {Duration, DurationFormat, JtlPlugin, LicenseType} from "../types/license";
import {NewLicenseData} from "../types/api";
import {useEffect, useRef} from "react";

export const isAdmin = (userType: User['userType']): boolean => !!(userType && userType === ADMIN_USER);

export const checkSession: () => Promise<User | null> = async () => {
    let user: User | null = null;
    if (localStorage.getItem('session_id') && localStorage.getItem('user_name')) {
        let session_id = localStorage.getItem('session_id')
        let user_name = localStorage.getItem('user_name')
        await validateUserSession(session_id, user_name).then(({ result, status}) => {
            if (result && result.session_id === session_id) {
                user = result;
            }
        })
    }
    return user;
}

export const addUserToLocalStorage = (session_id: string, user_name: string) => {
    localStorage.setItem('session_id', session_id);
    localStorage.setItem('user_name', user_name);
}

export const removeUserToLocalStorage = () => {
    localStorage.removeItem('session_id');
    localStorage.removeItem('user_name');
}

export const getLicenseType = (license: LicenseType): string | number => LicenseType[license] || license;

export const getShopVersionFromPluginVersion = (version: JtlPlugin["version"]) => {
    let v: number = Number(version);
    if (v < 500) {
        return "Shop 4";
    }
    if (v < 600) {
        return "Shop 5";
    }
}

export const translateDurationString = (duration: Duration): string => {
    if (duration) {
        if(duration === "420Y") {
            return "lebenslang";
        }
        duration = duration.replace(/Y/, " Jahr(e)")
        duration = duration.replace(/D/, " Tag(e)")
    }

    return duration || "";
}

export const getDemoDurationForLicense = (licenseType: LicenseType, format: DurationFormat = "readable"): string => {
    return returnDemoDuration("14D", format);
}

const returnDemoDuration = (demoDuration: string, format: DurationFormat) => format === "readable" ? translateDurationString("1Y") : demoDuration;

export const getDefaultDurationForLicense = (licenseType: LicenseType, format: DurationFormat = "readable"): string => {
    licenseType = Number(licenseType);
    if (licenseType === LicenseType["Abonnement"] || licenseType === LicenseType["Update Abonnement"]) {
        return returnDemoDuration("1Y", format);
    }
    if (licenseType === LicenseType["Einmalige Kauflizenz"] || licenseType === LicenseType["Kundenlizenz"] || licenseType === LicenseType["Lebenslange Lizenz"]) {
        return returnDemoDuration("420Y", format);
    }
    if (licenseType === LicenseType["Demo Lizenz"]) {
        return returnDemoDuration("14D", format);
    }
    return `Keine Dauer für Lizenztyp ${licenseType} definiert`
}


const stringIsNumber = (value: string) => !isNaN(Number(value));

export const enumToArray = (oEnum: { [key: string]: string | number }) => Object.keys(oEnum)
    .filter(stringIsNumber)
    .map(key => Number(key));

export const changeType = <T extends {}>(values: any) :T => values as T

export const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}