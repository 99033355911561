import {GridMarginObject, GridMarginType, margins} from "../constants/styles";

export const getGridMargin = (mType: GridMarginType["marginType"], pType: GridMarginType["paddingType"]): GridMarginObject | {} => {
    let cssSettings = {};
    if (mType && mType !== "none") {
        cssSettings = {
            marginTop: margins[mType],
            marginBottom: margins[mType]
        }
    }
    if (pType && pType !== "none") {
        cssSettings = {
            ...cssSettings,
            paddingTop: margins[pType],
            paddingBottom: margins[pType]
        }
    }
    return cssSettings;
}