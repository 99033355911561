import React, {PropsWithChildren, useEffect} from 'react';
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import {JtlPlugin} from "../../types/license";
import {
    getDefaultDurationForLicense,
    getLicenseType,
    getShopVersionFromPluginVersion,
    translateDurationString
} from "../../utils/functions";
import {NewPluginData} from "../../types/api";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type DisplayPluginRowsRowsProps = {
    plugins: [JtlPlugin]
    editPlugin: (pluginData: NewPluginData, pluginId: number) => void
}

export const DisplayPluginRows: React.FunctionComponent<DisplayPluginRowsRowsProps> = ({plugins, editPlugin}) => (
    <React.Fragment>
        {plugins.map((plugin, index) => (
            <PluginTableRow key={index} plugin={plugin} editPlugin={editPlugin}/>
        ))}
    </React.Fragment>
);

type PluginTableRowProps = {
    plugin: JtlPlugin
    editPlugin: (pluginData: NewPluginData, pluginId: number) => void
}

const PluginTableRow: React.FunctionComponent<PluginTableRowProps> = React.memo(({plugin, editPlugin}) => (
    <TableRow component={"div"}>
        <TableCell component={"div"}>
            {plugin.name}
        </TableCell>
        <TableCell component={"div"}>
            {plugin.pid}
        </TableCell>
        <TableCell component={"div"}>{plugin.secretKey}</TableCell>
        <TableCell component={"div"}>{plugin.version}</TableCell>
        <TableCell component={"div"}>{getShopVersionFromPluginVersion(plugin.version)}</TableCell>
        <TableCell component={"div"}>
            {plugin.duration ? translateDurationString(plugin.duration) : getDefaultDurationForLicense(plugin.plugin_licenseType)}
        </TableCell>
        <TableCell component={"div"}>
            {plugin.demo_duration ? translateDurationString(plugin.demo_duration) : getDefaultDurationForLicense(plugin.plugin_licenseType)}
        </TableCell>
        <TableCell component={"div"}>{getLicenseType(plugin.plugin_licenseType)}</TableCell>
        <TableCell component={"div"}>
            <IconButton onClick={() => editPlugin(plugin, plugin.pid)}>
                <FontAwesomeIcon icon={faEdit}/>
            </IconButton>
        </TableCell>
    </TableRow>
), (prevProps, nextProps) => prevProps.editPlugin !== nextProps.editPlugin);