import React from 'react';
import {TableCell, TableRow} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

type SkeletonTableCellsProps = {
    colCount?: number,
}

export const SkeletonTableCells: React.FunctionComponent<SkeletonTableCellsProps> = ({colCount= 1}) => {
    return <React.Fragment>
        {[...Array(colCount)].map((value, index) => <TableCell key={index} component={"div"}>
                <Skeleton variant="rect" width={'100%'} height={24}/>
            </TableCell>
        )}
    </React.Fragment>
}