import React from "react";
import {Typography} from "@material-ui/core";
import {GridContainer} from "../../components/sharded/layout/GridContainer";
import {GridItem} from "../../components/sharded/layout/GridItem";

export const LoggedOut: React.FunctionComponent = () => (
    <React.Fragment>
        <GridContainer>
            <GridItem xs={12} marginType={"xxl"}>
                <Typography variant={"h2"}>Sucessfully logged out!</Typography>
            </GridItem>
        </GridContainer>
    </React.Fragment>
)