export type LicenseDomainPlugin = License & Domain & JtlPlugin & {
    [key: string]: any;
};

export type License = {
    end_date: string|null,
    buy_date: string,
    license_type: number,
    updated_at: string|null,
    did: number,
    id: number,
    pid: number,
}

export type Duration = string|null;

export type Domain = {
    TEMPLATE_author: string
    TEMPLATE_cTemplate: string,
    TEMPLATE_name: string,
    TEMPLATE_parent: string|null,
    TEMPLATE_version: number,
    did: number,
    domain: string,
    end_date: string,
    ioncube_mav: string,
    ioncube_miv: string,
    jtl_buv: string,
    jtl_mav: string,
    jtl_miv: string,
    licenseType: LicenseType,
    php_mav: string,
    php_miv: string,
    updated_at: string,
    webDomain: string,
}

export type JtlPlugin = {
    duration: Duration,
    demo_duration: Duration,
    plugin_licenseType: LicenseType,
    name: string
    pid: number,
    version: string,
    secretKey: string
}

export type DurationFormat = "readable"| "raw";

export enum LicenseType {
    "Demo Lizenz" = 30,
    "Kundenlizenz" = 2,
    "Update Abonnement" = 15,
    "Einmalige Kauflizenz" = 99,
    "Lebenslange Lizenz" = 42,
    "Abonnement" = 14
}