import React from 'react';
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {JtlPlugin} from "../../../../types/license";

type SelectPluginProps = {
    handleChange: (value: JtlPlugin) => void,
    plugins: [JtlPlugin],
}

export const SelectPlugin: React.FunctionComponent<SelectPluginProps> = ({handleChange, plugins}) => {
    return (
        <React.Fragment>
            {plugins && plugins.length > 1 && (
                <Autocomplete
                    fullWidth
                    disableClearable
                    id="select-plugin"
                    options={plugins}
                    defaultValue={plugins[0]}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => handleChange(value)}
                    renderInput={(params) =>
                        <TextField {...params} label="Plugin" variant="outlined"/>
                    }
                />)}
        </React.Fragment>
    );
}