import React from "react";
import {Button, Card, TextField, Typography} from "@material-ui/core";
import {GridContainer} from "../../components/sharded/layout/GridContainer";
import {GridItem} from "../../components/sharded/layout/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import {margins} from "../../constants/styles";
import {Formik} from 'formik';
import {User, UserContext} from "../../context/userContext";
import {Redirect} from "react-router";
import {getProjectOverviewPath} from "../../routes/paths";
import {logInUser} from "../../apiCalls/serverRequests/post";

const useStyles = makeStyles({
    card: {
        padding: margins['l'],
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    textF: {
        margin: margins['s'] + ' 0',
    },
});


export const LogIn: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <UserContext.Consumer>
                {({ user, setUser }) => user.userId ? (
                    <Redirect to={getProjectOverviewPath()} />
                ) : (
                        <GridContainer justify={"center"}>
                            <GridItem xs={12} sm={8} md={4} marginType={"xxl"}>
                                <Card className={classes.card}>
                                    <Typography variant={"h2"}>Log in</Typography>
                                    <SubmitForm setUser={setUser} />
                                </Card>
                            </GridItem>
                        </GridContainer>
                    )}
            </UserContext.Consumer>
        </React.Fragment>
    )
}

const SubmitForm: React.FunctionComponent<{ setUser: (user: User) => void }> = ({ setUser }) => {
    const classes = useStyles();
    return (
        <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            initialValues={{
                name: "",
                password: "",
            }}

            onSubmit={(values, actions) => {
                logInUser(values.name, values.password)
                    .then((response) => {
                        if (response && response.userId) {
                            setUser(response)
                        } else {
                            actions.setStatus({ falseLogin: true })
                            actions.setSubmitting(false);
                        }
                    })
            }}
        >
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <div className={classes.root}>
                        <TextField className={classes.textF} required
                            id="name"
                            name="name"
                            label="User name"
                            autoComplete="user-name"
                            onChange={props.handleChange}
                            value={props.values.name}
                        />
                        <TextField className={classes.textF}
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            onChange={props.handleChange}
                            value={props.values.password}
                        />
                        {props.status && props.status.falseLogin && (
                            <Typography color="error" align="left" variant="body2" style={{fontWeight: 600}}>
                                Benutzername oder Passwort falsch!
                            </Typography>
                        )}
                        <Button disabled={props.isSubmitting} className={classes.textF} color="primary"
                            variant="contained" type="submit">
                            Log In
                        </Button>
                    </div>
                </form>
            )}
        </Formik>)
}