export const ADMIN_USER = 1;
export const EMPLOYEE_USER = 2;
export const CUSTOMER_USER_ADMIN = 3;
export const CUSTOMER_USER = 4;

export const APP_NAME = "Lizenzverwaltung";
export const MOGLI_MT_ACCOUNT_ID = 55076041;

export const months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'July',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
]