import {USER_DEFAULT_VALUE, UserContextType} from "../../context/userContext";
import React, {ReactElement, ReactNode, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {getLogOutPath} from "../../routes/paths";
import {History} from 'history';
import {removeUserToLocalStorage} from "../../utils/functions";

interface LogOutUserProps extends RouteComponentProps<any> {
    setUser: UserContextType['setUser'];
    history: History;
    renderComp: (logOut: () => void) => ReactNode;
}

const InnerLogOutUser: React.FunctionComponent<LogOutUserProps> = ({setUser, renderComp, history}): ReactElement | any => {
    const [loggedOut, setLoggedOut] = useState(false);
    const logOut: () => void = () => {
        setLoggedOut(true);
        setUser(USER_DEFAULT_VALUE);
        removeUserToLocalStorage();
        history.push(getLogOutPath())
    }
    return (!loggedOut && (
            <React.Fragment>
                {renderComp(logOut)}
            </React.Fragment>)
    )
}

export const LogOutUser = withRouter(InnerLogOutUser);