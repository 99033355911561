export const baseHeaders = {
    'Authorization': 'Bearer ',
}

const resourceUrl: string = "https://meistertask.knowmates.de";

const apiV: string = 'api/v1';

export const baseUrl: string = resourceUrl + "/" + apiV + "/";

const licenseUrl: string = "https://test.lizenz.knowmates.de/";

const licenseApiV: string = 'api/v3';

export const licenseBaseUrl: string = licenseUrl + licenseApiV + "/license/manage_license/"