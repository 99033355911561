import React from "react";
import {Grid, GridProps} from "@material-ui/core";
import {getGridMargin} from "../../../utils/gridFunctions";
import {GridMarginType} from "../../../constants/styles";

export const GridContainer: React.FunctionComponent<GridMarginType & GridProps> = ({marginType = "none", paddingType = "none", children, ...props}) => {
    return (
        <Grid style={getGridMargin(marginType, paddingType)} container {...props}>
            {children}
        </Grid>
    )
}