import React from 'react';
import {enumToArray} from "../../../../utils/functions";
import {LicenseType} from "../../../../types/license";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

type SelectLicenseTypeProps = {
    handleChange: (value: number) => void
}

export const SelectLicenseType: React.FunctionComponent<SelectLicenseTypeProps> = ({ handleChange}) => {
    return <Autocomplete
        fullWidth
        disableClearable
        id="select-licensetype"
        options={enumToArray(LicenseType)}
        defaultValue={LicenseType["Demo Lizenz"]}
        getOptionLabel={(option: number) => LicenseType[option]}
        onChange={(event, value) => handleChange(value)}
        renderInput={(params) =>
            <TextField {...params} label="Lizenztyp" variant="outlined"/>}
    />
}