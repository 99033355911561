import React from 'react';
import {TableCell} from "@material-ui/core";
import {LicenseDomainPlugin} from "../../types/license";
import {convertDateToFormatWithTime} from "../../utils/date";
import {getLicenseType} from "../../utils/functions";
import {SearchObject} from "./DisplayLicenses";
import LazyLoad from "react-lazyload";
import "../../css/lazyload.css";
import {SkeletonTableCells} from "../../components/sharded/loader/SkeletonTableCells";

type DisplayLicenseTableProps = {
    licenses: [LicenseDomainPlugin]
    search: { [key: string]: SearchObject }
}

export const DisplayLicenseRows: React.FunctionComponent<DisplayLicenseTableProps> = ({licenses, search}) => {
    const searchColumns = () => {
        Object.keys(search).map(key => {
            if (search[key] && search[key].value !== "") {
                // @ts-ignore
                licenses = licenses.filter(license => license[search[key].column].toLowerCase().includes(search[key].value.toLowerCase()))
            }
        })
    }
    searchColumns();
    return (
        <React.Fragment>
            {licenses.map((license, index) => license && (
                <LazyLoad offset={200} key={index} placeholder={<SkeletonTableCells colCount={5}/>}>
                    <TableCell component={"div"}>
                        {license.domain}
                    </TableCell>
                    <TableCell component={"div"}>
                        {license.name}
                    </TableCell>
                    <TableCell component={"div"}>{convertDateToFormatWithTime(license.buy_date)}</TableCell>
                    <TableCell
                        component={"div"}>{license.end_date ? convertDateToFormatWithTime(license.end_date) : "-"}</TableCell>
                    <TableCell component={"div"}>{getLicenseType(license.licenseType)}</TableCell>
                </LazyLoad>
            ))}
        </React.Fragment>
    );
}