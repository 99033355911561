import {Link, LinkProps} from "react-router-dom";
import * as React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noStyleLink: {
            color: 'inherit',
            textDecoration: 'none',
            '&:focus, &:hover, &:visited, &:link, &:active': {
                color: 'inherit',
                textDecoration: 'none',
            }
        },
    }),
);

export const NoStyleLink: React.FunctionComponent<LinkProps> = ({children, ...props}) => {
    const classes = useStyles();
    return (
        <Link className={classes.noStyleLink} {...props} >
            {children}
        </Link>
    );
}