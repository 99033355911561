import React, {useState} from 'react';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Modal,
    OutlinedInput,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import {v4 as uuidv4} from 'uuid';
import {dFlex, margins, mAuto, mLeftAuto} from "../../../constants/styles";
import {GridContainer} from "../../sharded/layout/GridContainer";
import {GridItem} from "../../sharded/layout/GridItem";
import {JtlPlugin, LicenseType} from "../../../types/license";
import {Formik} from "formik"
import {SelectLicenseType} from "./formFields/SelectLicenseType";
import {insertNewPlugin, updatePlugin} from "../../../apiCalls/serverRequests/post";
import {NewPluginData} from "../../../types/api";
import {getDefaultDurationForLicense, getDemoDurationForLicense} from "../../../utils/functions";
import {API_ERROR} from "../../../constants/api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedo} from "@fortawesome/free-solid-svg-icons";

type AddPluginProps = {
    onSuccess: () => void,
    pluginId: JtlPlugin["pid"],
    initialValues: NewPluginData | JtlPlugin,
    open: boolean
    setOpen: (open: boolean) => void
}

export const AddPlugin: React.FunctionComponent<AddPluginProps> = ({open,pluginId, setOpen, onSuccess, initialValues}) => {

    const [submitErrorMsg, setSubmitErrorMsg] = useState<string>("");

    const doApiCall = (values: NewPluginData) => (pluginId === -1) ? insertNewPlugin(values) : updatePlugin({
        ...values,
        pid: pluginId,
    });

    const submit_button_text = `Plugin ${pluginId === -1 ? 'hinzufügen' : 'speichern'}`;

    const closeModal = () => setOpen(false);
    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={dFlex}
            >
                <Paper style={{...mAuto, padding: margins["m"], width: 1000, maxWidth: "100%"}}>
                    <Formik<NewPluginData | JtlPlugin>
                        validateOnBlur={false}
                        validateOnChange={false}
                        validateOnMount={false}
                        initialValues={initialValues}
                        onSubmit={(values) => {
                            doApiCall(values).then(({status}) => {
                                if (status && status.type === API_ERROR) {
                                    setSubmitErrorMsg(status.message);
                                } else {
                                    setSubmitErrorMsg("");
                                    onSuccess();
                                    closeModal();
                                }
                            });
                        }}>
                        {props => {
                            const handleAutoCompleteChange = (value: LicenseType) => {
                                props.setValues({
                                    ...props.values,
                                    plugin_licenseType: value,
                                    demo_duration: getDemoDurationForLicense(value, "raw"),
                                    duration: getDefaultDurationForLicense(value, "raw")
                                })
                            }
                            const setUUID = () => props.setValues({
                                ...props.values,
                                secretKey: uuidv4(),
                            })
                            return (
                                <form onSubmit={props.handleSubmit}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                name="name"
                                                value={props.values.name}
                                                label={"Plugin Name"}
                                                onChange={props.handleChange}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="secretKey">Schlüssel</InputLabel>
                                                <OutlinedInput
                                                    id="secretKey"
                                                    type="text"
                                                    name="secretKey"
                                                    value={props.values.secretKey}
                                                    onChange={props.handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="set-uuid"
                                                                onClick={setUUID}
                                                                edge="end"
                                                            >
                                                                <FontAwesomeIcon icon={faRedo}/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                name="version"
                                                label="Pluginversion (ohne Punkte, z.B:500)"
                                                value={props.values.version}
                                                onChange={props.handleChange}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <SelectLicenseType
                                                handleChange={(value) => handleAutoCompleteChange(value)}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                name="duration"
                                                label={"Lizenz-Dauer"}
                                                value={props.values.duration}
                                                onChange={props.handleChange}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                name="demo_duration"
                                                value={props.values.demo_duration}
                                                label={"Demo-Dauer"}
                                                onChange={props.handleChange}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <div style={dFlex}>

                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} style={dFlex}>
                                            <div style={mLeftAuto}>
                                                <Button variant="contained" color="default" onClick={closeModal}>
                                                    schließen
                                                </Button>
                                                <Button style={{marginLeft: margins["s"]}} variant="contained"
                                                        color="primary" type="submit">
                                                    {submit_button_text}
                                                </Button>
                                            </div>
                                        </GridItem>
                                        {submitErrorMsg !== "" && (
                                            <GridItem xs={12} style={dFlex}>
                                                <div style={mLeftAuto}>
                                                    <Typography color="error">{submitErrorMsg}</Typography>
                                                </div>
                                            </GridItem>
                                        )}
                                    </GridContainer>
                                </form>
                            )
                        }}
                    </Formik>
                </Paper>
            </Modal>
        </React.Fragment>
    );
}