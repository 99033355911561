import {baseUrl, licenseBaseUrl} from "./settings";
import {addUserToLocalStorage} from "../../utils/functions";
import {fetchPostWithAuth} from "../../utils/api";
import {ApiNewPluginData, NewLicenseData} from "../../types/api";
import {JtlPlugin} from "../../types/license";

export const logInUser = (userName: string, password: string) => {
    return fetchPostWithAuth(baseUrl + 'user/login', JSON.stringify({userName: userName, password: password}))
        .then(({result, status}) => {
            if (result.userName && result.session_id) {
                addUserToLocalStorage(result.session_id, result.userName);
            }
            return result
        })
}

export const validateUserSession = (session_id: string | null, user_name: string | null) => fetchPostWithAuth(
    baseUrl + 'user/session/validate', JSON.stringify({session_id, user_name})
)

export const insertNewLicense = (params: NewLicenseData) => fetchPostWithAuth(licenseBaseUrl + "license", JSON.stringify(params))

export const insertNewPlugin = (params: ApiNewPluginData) => fetchPostWithAuth(licenseBaseUrl + "plugin", JSON.stringify(params))

export const updatePlugin = (params: JtlPlugin) => fetchPostWithAuth(licenseBaseUrl + "plugin", JSON.stringify(params))
