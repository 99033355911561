import {APIResult} from "../types/api";
import {baseHeaders} from "../apiCalls/serverRequests/settings";

export const fetchWithAuth = <T extends {}>(url: string, headers: any = baseHeaders, method = 'GET') => {
    headers = {
        ...headers,
        'Authorization': 'Bearer ' + localStorage.getItem('session_id'),

    }
    return fetch(url, {
        method,
        headers,
    })
        .then(res =>  res.json())
        .then((result: APIResult<T>) => result, (error) => error)
}

export const fetchPostWithAuth = <T extends {}>(url: string, body: any, method = 'POST', headers: any = baseHeaders) => {
    headers = {
        ...headers,
        'Authorization': 'Bearer ' + localStorage.getItem('session_id'),
    }
    return fetch(url, {
        method,
        headers, body
    })
        .then(res => res.json())
        .then((result: APIResult<T>) => result, (error) => error);
}
