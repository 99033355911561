import React from 'react';
import {Autocomplete} from "@material-ui/lab";
import {Domain} from "../../../../types/license";
import {TextField} from "@material-ui/core";

type DomainInputProps = {
    handleChange: (value: string) => void,
    domains: [Domain],
}

export const DomainInput: React.FunctionComponent<DomainInputProps> = ({domains, handleChange}) => {
    return (
        <Autocomplete
            fullWidth
            freeSolo
            id="domain-select"
            options={domains}
            defaultValue={""}
            getOptionLabel={(domain: Domain | string) => typeof domain === "string" ? domain : domain.domain}
            onChange={(event, value) =>
                value && handleChange(typeof value === "string" ? value : value.domain)
            }
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant="outlined"
                    label={"Domain (ohne https://www.)"}
                    onChange={(event) => handleChange(event.target.value)}
                    placeholder={"shop.knowmates.de"}
                />
            }
        />
    )
}